import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { FC, useEffect, useState, useMemo, useRef, Fragment } from "react";
import { Link, useNavigate, useParams,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "context/CartContext";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import SkewLoader from "react-spinners/SkewLoader";
// import optional lightbox plugins
import { SlideshowLightbox } from 'lightbox.js-react'
import YouTube from 'react-youtube';
import ThreeDDownload from '../../ThreeDDownload.jpeg'
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ProductTable from "./ProductTable";

export interface ProductDetailPage2Props {
  className?: string;
}

interface HeaderRow {
  // Define the properties of your header row here
  [key: string]: string;
}

interface BodyRow {
  // Define the properties of your body row here
  [key: string]: string;
}

const ProductDetail: FC<ProductDetailPage2Props> = ({
  className = "",
}) => {
  const location = useLocation();
  const initialRender = useRef(true);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [isVerify, setIsVerify] = useState(false);
  const [headerRows, setHeaderData] = useState<HeaderRow[]>([]);
  const [bodyRows, setBodyData] = useState<BodyRow[]>([]);
  const [filterBodyRows, setFilterBodyData] = useState<BodyRow[]>([]);
  const [groupData, setGroupData] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [selectedValues, setSelectedValues] = useState<BodyRow[]>([]);
  const [color, setColor] = useState("#2A3491");
  const { cartCount, updateCart, addToCart } = useCart();
  const [showVideo, setShowVideo] = useState(false);
  const [videoId, setVideoID] = useState('');
  const [productData, setProductData] = useState({
    id: "",
    title: "",
    slug: "",
    sub_title: "",
    meta_title: "",
    meta_desc: "",
    raw_material: "",
    case_hardness: "",
    note: "",
    category_id: "",
    sub_category_id: "",
    data_sheet: "",
    product_sheet: "",
    image: "",
    line_drawing: "",
    filter_option: [],
    product_header_data: [],
    product_data: [],
    product_table_header_data: {},
    product_table_body_data: [],
    youTube_url: "",
  });

  // useEffect(() => {
  //   getGroupDetails();
  //   setIsApiCall(1);
  //   if (localStorage.getItem("AuthCustomerDetails")) {
  //     const authUserDetails = JSON.parse(localStorage.getItem("AuthCustomerDetails") || "{}");
  //     if (authUserDetails && authUserDetails.verify_status == "Active") {
  //       setIsVerify(true);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    getGroupDetails();
    setIsApiCall(1);
    if (localStorage.getItem("AuthCustomerDetails")) {
      const authUserDetails = JSON.parse(localStorage.getItem("AuthCustomerDetails") || "{}");
      if (authUserDetails && authUserDetails.verify_status == "Active") {
        setIsVerify(true);
      }
    }
  }, [params]);


  const { data } = useSelector((state: RootState) => state.AppMaster);
  const { loading } = useSelector((state: RootState) => state.AppMaster);

  const getGroupDetails = () => {
    setGroupData(true);
    let filterData = {
      slug: params.slug
    }
    dispatch(onGetData(filterData, '', api.frontProductGroupDetail));
  }

  const [isOpen, setIsOpen] = useState(false);

  // State to track the index of the image to be displayed in the lightbox
  const [lightboxIndex, setLightboxIndex] = useState(0);

  if (data && groupData == true) {
    if (data.productGroup) {
      setGroupData(false);

      if (data.productGroup.product_header_data != null) {
        if (data.productGroup.product_header_data.length > 0) {
          setHeaderData(data.productGroup.product_header_data)
        } else {
          const firstObject = data.productGroup.product_data[0];
          // Set the header data using the first object
          setHeaderData([firstObject]);
        }
      }
      if (data.productGroup.product_data != null) {
        if (data.productGroup.product_header_data.length === 0 && data.productGroup.product_data.length > 0) {
          // If product_header_data is empty and product_data has elements
          // Remove the first row from product_data
          const bodyData = data.productGroup.product_data.slice(1);
          setBodyData(bodyData);
          setFilterBodyData(bodyData);
        } else {
          // Otherwise, set the body data as it is
          setBodyData(data.productGroup.product_data);
          setFilterBodyData(data.productGroup.product_data);
        }
      }

      setProductData((preValue) => {
        return {
          ...preValue,
          id: data.productGroup._id,
          title: data.productGroup.title,
          slug: data.productGroup.slug,
          sub_title: data.productGroup.sub_title,
          meta_title: data.productGroup.meta_title,
          meta_desc: data.productGroup.meta_desc,
          raw_material: data.productGroup.raw_material,
          case_hardness: data.productGroup.case_hardness,
          note: data.productGroup.note,
          category_id: data.productGroup.category,
          sub_category_id: data.productGroup.sub_category,
          product_header_data: data.productGroup.product_header_data,
          product_data: data.productGroup.product_data,
          product_table_header_data: data.productGroup.product_table_header_data || '',
          product_table_body_data: data.productGroup.product_table_body_data || '',
          filter_option: (data.productGroup?.filter_option) || [],
          data_sheet: (data.productGroup.data_sheet?.url) || "",
          product_sheet: (data.productGroup.product_sheet?.url) || "",
          image: (data.productGroup.image?.url) || "",
          line_drawing: (data.productGroup.line_drawing?.url) || "",
          youTube_url: data.productGroup.youTube_url,
        };
      });
    }else if(!loading){
      return<div style={{
        backgroundColor: 'white',
        color: 'black',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        maxWidth: '400px',
        margin: '30px auto',
        border: '1px solid #ccc',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <h1 style={{ marginBottom: '20px', fontWeight:'bolder' }}>No Product Found</h1>
        <p>We're sorry, but it seems no products matches with your query. Please check back later or contact support for assistance.</p>
        <div className="pt-8">
          <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
        </div>
      </div>
    }

  }

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const calculateColSpan = (currentLetters: string[], cellIndex: number) => {
    const cellLetter = currentLetters[cellIndex];
    let count = 1;
    for (let i = alphabet.indexOf(cellLetter) + 1; i < alphabet.length; i++) {
      const currentLetter = alphabet[i];
      if (!currentLetters.includes(currentLetter)) {
        count++;
      } else {
        break;
      }
    }
    return count;
  };

  const calculateRowSpan = (index: number, letter: string) => {
    let count = 1;
    for (let i = index + 1; i < headerRows.length; i++) {
      if (Object.keys(headerRows[i]).includes(letter)) {
        break;
      }
      count++;
    }
    return count;
  };

  const handleChange = (event: any, key: any) => {

    const selectedValue = event.target.value;
    let newSelectedValuesobj = selectedValues;
    newSelectedValuesobj[key] = selectedValue

    setSelectedValues((preValue) => {
      return {
        ...preValue,
        [key]: selectedValue
      };
    });
    // console.log(typeof(newSelectedValuesobj),selectedValues);


    applyFilter()
  };

  const applyFilter = () => {

    const searchTerms = Object.entries(selectedValues);
    let filteredProducts = filterBodyRows;

    filteredProducts = filteredProducts.filter(item => {
      return searchTerms.every(([key, value]) => {

        if (!item.hasOwnProperty(key)) {
          return false; // Key not present in item, skip this filter
        }
        if (typeof value === "string" && value === "") {
          return true;
        }
        if (typeof item[key] === 'string' && typeof value === 'string') {
          return item[key] === value;
        } else if (typeof item[key] === 'object' && typeof value === 'object') {
          // If both values are objects, ensure they are equal (assuming they are JSON serializable)
          return JSON.stringify(item[key]) === JSON.stringify(value);
        } else if (typeof item[key] === 'number' && typeof value === 'string') {
          return item[key] == value;
        } else {
          return false; // Types cannot be compared, skip this filter
        }
      });
    });

    setBodyData(filteredProducts)
  }

  const removeFilter = () => {
    setFilter(false);
    setSelectedValues([]);
    //  setBodyData(productData.product_data);

    if (productData.product_data != null) {
      if (productData.product_header_data.length === 0 && productData.product_data.length > 0) {
        // If product_header_data is empty and product_data has elements
        // Remove the first row from product_data
        const bodyData = productData.product_data.slice(1);
        setBodyData(bodyData);
      } else {
        // Otherwise, set the body data as it is
        setBodyData(productData.product_data);
      }
    }
  }


  // Function to handle opening the lightbox
  const openLightbox = (index: number) => {
    setIsOpen(true);
    setLightboxIndex(index);
  };

  const handleClick: () => void = () => {
    setShowVideo(!showVideo);
    // @ts-ignore
    const videoId = productData.youTube_url ? productData.youTube_url.split("/").pop().split("?")[0] : '';

    setVideoID(videoId);
  };

  const handleLoginClick = () => {
      const currentUrl = location.pathname;
      localStorage.setItem('redirectUrl',currentUrl);
  };

  const renderSectionSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap lg:shadow-lg border-0">
        {loading && (
          <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
            <div className="absolute z-50">
              <SkewLoader color={color} loading={loading} />
            </div>
          </div>
        )}
        <div className="space-y-7 lg:space-y-8">
          <div>
            <SlideshowLightbox modalClose="clickOutside" showThumbnailIcon={false} className="container grid grid-cols-3 gap-2 mx-auto lightbox-custom"
              images={
                productData && productData.image ? [{ src: productData.image }] : []
              }
              showThumbnails={true}
              open={isOpen && lightboxIndex === 0}
              lightboxIdentifier="lbox1"
              onClose={() => setIsOpen(false)}
            />
            <Link to="#">
              <NcImage
                containerClassName="nc-NcImage aspect-w-6 aspect-h-6"
                className="w-full"
                onClick={() => openLightbox(0)}
                src={productData && productData.image}
              />
            </Link>
            <hr />
            {/* PRICE */}
            <div className="my-5">
              {/* ---------- 1 HEADING ----------  */}
              <div className="flex items-center justify-between space-x-5">
                <div className="flex text-base font-semibold">
                  Product data sheet
                </div>
                <a href={productData && productData.data_sheet} target="_blank" rel="noreferrer">
                  <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                  </svg>
                </a>

              </div>

            </div>
            {productData.youTube_url &&
              <div className="text-base font-semibold cursor-pointer bg-red-600 text-white p-2 xl:w-[38%] md:[50%] w-[60%] rounded-lg text-center" onClick={handleClick}>
                Watch Video
              </div>}


          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6 border-0 pb-0">
        <div>
          <div className="flex justify-between">
            <div>
              <h2 className="text-2xl md:text-3xl font-semibold text-neutral-500 dark:text-neutral-400">
                {productData && productData.title}
              </h2>
              <h3 className="font-semibold text-neutral-500 dark:text-neutral-400">
                {productData && productData.sub_title}
              </h3>
            </div>
          </div>

          {productData && productData.product_table_header_data && <ProductTable product_data_headers={Object.values(productData.product_table_header_data)} product_data_rows={productData.product_table_body_data} />}

          {productData && productData.raw_material && (
            <p>{productData && productData.raw_material}</p>
          )}

          {productData && productData.raw_material && (
            <p>{productData && productData.case_hardness}</p>
          )}

          {productData && productData.note && (
            <p>{productData && productData.note}</p>
          )}

          <div className="flex items-center mt-10">
            <SlideshowLightbox modalClose="clickOutside" showThumbnailIcon={false}
              images={
                productData && productData.line_drawing ? [{ src: productData.line_drawing }] : []
              }
              showThumbnails={true}
              open={isOpen && lightboxIndex === 1}
              lightboxIdentifier="lbox2"
              onClose={() => setIsOpen(false)}
            />
            <Link to="#">
              <NcImage
                containerClassName=""
                className=""
                onClick={() => openLightbox(1)}
                src={productData && productData.line_drawing}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ProductDetailPage2 ${className}`}
      data-nc-id="ProductDetailPage2"
    >
      <Helmet>
        <title>{productData && productData.title} | Vijaydeep</title>
        <meta
          name="title"
          content={productData && productData.meta_title}
        />
        <meta
          name="title"
          content={productData && productData.meta_desc}
        />
      </Helmet>
      {/* MAIn */}
      <main className="container relative z-10 mt-9 sm:mt-11 md:flex lg:flex sx:flex-wrap">
        {/* CONTENT */}

        {/*   <div className="absolute">
            <div className="flex gap-2 ">
              <YouTube videoId={videoId} />
              <div className="bg-[#06163A] text-white flex w-[25px] h-[25px] text-lg items-center justify-center text-center cursor-pointer" onClick={handleClick}>
                X</div>
            </div>
          </div> */}
          {showVideo && (
            <Transition appear show={true} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50"
              onClose={handleClick}
            >
              <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="inline-block align-middle" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
                    <div
                      className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white
                    dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
                    >
                      <span className="absolute right-3 top-3 z-50 mb-4">
                        <ButtonClose onClick={handleClick} />
                      </span>

                      <div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar pt-5">
                      <div className="vedio-size">
                      <YouTube videoId={videoId}/>
                      </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
           )}

        <div className="w-full lg:w-3/5 xl:w-2/3 md:w-2/3 space-y-10 lg:pr-14 lg:space-y-14">
          {renderSection1()}
        </div>

        {/* SIDEBAR */}
        <div className="flex-grow shrink">
          {renderSectionSidebar()}
        </div>
      </main>

      {/* Table SECTION */}
      <div className="container pb-24 lg:pb-28 space-y-8 mt-10 mt-md-0">
        {/* <hr className="border-slate-200 dark:border-slate-700" />
        <h3 className="text-1xl md:text-3xl font-semibold text-neutral-500">
          Selection Of The Products {productData && productData.title}
        </h3> */}

        <div className="overflow-hidden">
          <div className="overflow-x-scroll">
            <Paper>
              <Table aria-label="simple table" className="min-w-full">
                <TableHead className="bg-[#06163A] dark:bg-gray-700 border-b text-white product-table-header">
                  {headerRows.length > 0 && headerRows.map((row, rowIndex) => {
                    const currentLetters = Object.keys(row);
                    const isLastRow = rowIndex === headerRows.length - 1;

                    return (
                      <TableRow key={`header-row-${rowIndex}`}>
                        {Object.entries(row).map(([key, value], cellIndex) => {
                          const colSpan = calculateColSpan(currentLetters, cellIndex);
                          const rowSpan = calculateRowSpan(rowIndex, key);
                          const isRightAligned = colSpan > 1;
                         // const filterData = productData.filter_option;
                          const uniqueOptions = Array.from(new Set(filterBodyRows.map(option => option[key])));

                          if (value === 'Price') return null;
                          if (key === 'image') return null;
                          if (value == 'Discount') return null;
                          if (value == 'Economic Code') return null;
                          if (value == 'Economic Price') return null;
                          if (value == 'Economic Discount') return null;

                          const filterData: { key: string; value: string }[] = productData.filter_option || [];
                          const keyExistsInFilterData = filterData.some(obj => obj.key === key);

                          return (
                            <React.Fragment key={`header-cell-${cellIndex}`}>
                            {isLastRow &&
                                <TableCell
                                  align={isRightAligned ? "center" : 'center'}
                                  colSpan={1}
                                  rowSpan={1}
                                  className="text-md font-SemiBold px-6 py-4 text-left"
                                >
                                  {value}
                                </TableCell>
                            }

                            {!isLastRow && (
                              <TableCell
                                align={isRightAligned ? "center" : 'center'}
                                colSpan={colSpan}
                                rowSpan={rowSpan}
                                className="text-md font-SemiBold px-6 py-4 text-left border-y-0"
                              >
                                {value}
                              </TableCell>
                            )}
                          </React.Fragment>
                          );
                        })}
                        <TableCell
                          align={'center'}
                          key={'action-cell'}
                          colSpan={22}  // Adjust the colSpan as needed
                          rowSpan={1}
                          className="text-md font-SemiBold px-6 py-4 text-center divide-y whitespace-nowrap sticky right-0 bg-[#06163A]"
                        >
                          {isLastRow && (
                            <p></p>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {bodyRows.map((row, rowIndex) => {
                    if (rowIndex == 0) {
                      const currentLetters = Object.keys(row);
                      return (
                        <TableRow key={`header-row-${rowIndex}`}>
                          {Object.entries(row).map(([key, value], cellIndex) => {

                            const colSpan = calculateColSpan(currentLetters, cellIndex);
                            const rowSpan = calculateRowSpan(rowIndex, key);
                            const uniqueOptions = Array.from(new Set(filterBodyRows.map(option => option[key])));

                            const isRightAligned = colSpan > 1;
                            if (value === 'Price') return null;
                            if (key === 'image') return null;
                            if (value == 'Discount') return null;
                            if (value == 'Economic Code') return null;
                            if (value == 'Economic Price') return null;
                            if (value == 'Economic Discount') return null;

                            const filterData: { key: string; value: string }[] = productData.filter_option || [];
                            const keyExistsInFilterData = filterData.some(obj => obj.key === key);

                            return (
                              <>
                              {keyExistsInFilterData ? (
                                  <TableCell
                                    align={isRightAligned ? "center" : 'center'}
                                    colSpan={1}
                                    rowSpan={1}
                                    className="text-md font-SemiBold px-6 py-4 text-left"
                                  >
                                  <div className="flex-none ml-1">
                                      <select
                                        id={`countries_${key}`} // Add a unique identifier for each select element
                                        className="border border-gray-300 filter-text rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        name={value}
                                        onChange={(event) => handleChange(event, key)}
                                      >
                                        <option value=""></option>
                                        {uniqueOptions.map((option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </TableCell>
                              ):(
                                null
                              )}
                              </>
                            );
                          })}
                          <TableCell
                            align={'center'}
                            key={'action-cell'}
                            colSpan={22}  // Adjust the colSpan as needed
                            rowSpan={22}
                            className="text-md font-SemiBold px-6 py-4 text-center divide-y whitespace-nowrap sticky right-0 bg-[#06163A]"
                          >
                            <p>Action</p>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}

                </TableHead>

                <TableBody className="text-primary-500">
                  {headerRows.length == 0 && bodyRows.map((row, index) => {
                    if (index != 0 && headerRows.length == 0) {
                      return (
                        <TableRow key={`body-row-${index}`}>
                          {Object.entries(row).map(([key, cell]) => (
                            key === "image" || key === 'price' || key === 'discount' || key === 'economic_code' || key === 'economic_price' || key === 'economic_discount' ? (
                              null
                            ) : (
                              <TableCell key={`body-cell-${key}`} align="center">
                                {cell}
                              </TableCell>
                            )
                          ))}


                          <TableCell align="center" className="divide-y whitespace-nowrap sticky right-0 bg-white">
                            <div className="flex items-center justify-center">
                              {Object.entries(row).map(([key, cell]) => (
                                key === "image" && cell != null && cell !='404' ? (
                                  <a href={cell} download="" className="text-neutral-50 hover:text-secondary-50">
                                    <svg className="w-6 h-6 mr-2" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 303.000000 219.000000"
                                      preserveAspectRatio="xMidYMid meet">

                                      <g transform="translate(0.000000,219.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M225 2065 c-51 -18 -120 -91 -134 -141 -7 -26 -11 -284 -11 -800 0
-832 -1 -815 58 -877 15 -16 45 -39 67 -50 l40 -22 1185 -2 c754 -2 1205 1
1240 7 74 13 129 53 160 115 l25 50 3 764 c2 682 1 770 -14 813 -21 65 -58
106 -119 134 l-50 24 -1205 -1 c-1013 0 -1211 -2 -1245 -14z m1205 -367 c0 -4
-76 -103 -170 -220 -93 -117 -170 -219 -170 -225 0 -7 8 -13 18 -13 28 0 116
-45 156 -80 119 -105 138 -302 42 -443 -128 -189 -417 -223 -642 -77 -35 24
-68 50 -73 58 -7 12 5 36 40 89 27 40 52 73 55 73 4 0 20 -11 36 -26 80 -66
202 -99 303 -80 46 9 106 63 124 111 41 114 -67 215 -229 215 l-57 0 -22 51
c-13 31 -18 56 -12 63 101 128 251 326 251 331 0 3 -60 4 -132 3 l-133 -3 -5
-50 -5 -50 -90 0 -90 0 -3 102 c-4 142 9 165 98 175 56 6 710 2 710 -4z m474
-3 c121 -17 253 -87 324 -173 113 -134 157 -348 112 -546 -30 -136 -131 -272
-246 -333 -110 -58 -149 -65 -382 -70 -232 -6 -262 -1 -281 45 -8 19 -10 143
-9 392 l3 365 100 0 100 0 3 -312 2 -313 108 0 c124 0 194 17 260 62 181 125
188 498 13 637 -79 63 -125 73 -353 78 -115 3 -208 8 -208 12 0 8 58 79 107
132 l34 36 137 -3 c75 -2 155 -6 176 -9z"/>
                                      </g>
                                    </svg>

                                  </a>
                                ) : key === "image" && cell == null && (
                                  <a href="/login" className="text-neutral-50 hover:text-secondary-50">
                                    <svg className="w-6 h-6 mr-2" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 303.000000 219.000000"
                                      preserveAspectRatio="xMidYMid meet">

                                      <g transform="translate(0.000000,219.000000) scale(0.100000,-0.100000)"
                                        fill="#000000" stroke="none">
                                        <path d="M225 2065 c-51 -18 -120 -91 -134 -141 -7 -26 -11 -284 -11 -800 0
-832 -1 -815 58 -877 15 -16 45 -39 67 -50 l40 -22 1185 -2 c754 -2 1205 1
1240 7 74 13 129 53 160 115 l25 50 3 764 c2 682 1 770 -14 813 -21 65 -58
106 -119 134 l-50 24 -1205 -1 c-1013 0 -1211 -2 -1245 -14z m1205 -367 c0 -4
-76 -103 -170 -220 -93 -117 -170 -219 -170 -225 0 -7 8 -13 18 -13 28 0 116
-45 156 -80 119 -105 138 -302 42 -443 -128 -189 -417 -223 -642 -77 -35 24
-68 50 -73 58 -7 12 5 36 40 89 27 40 52 73 55 73 4 0 20 -11 36 -26 80 -66
202 -99 303 -80 46 9 106 63 124 111 41 114 -67 215 -229 215 l-57 0 -22 51
c-13 31 -18 56 -12 63 101 128 251 326 251 331 0 3 -60 4 -132 3 l-133 -3 -5
-50 -5 -50 -90 0 -90 0 -3 102 c-4 142 9 165 98 175 56 6 710 2 710 -4z m474
-3 c121 -17 253 -87 324 -173 113 -134 157 -348 112 -546 -30 -136 -131 -272
-246 -333 -110 -58 -149 -65 -382 -70 -232 -6 -262 -1 -281 45 -8 19 -10 143
-9 392 l3 365 100 0 100 0 3 -312 2 -313 108 0 c124 0 194 17 260 62 181 125
188 498 13 637 -79 63 -125 73 -353 78 -115 3 -208 8 -208 12 0 8 58 79 107
132 l34 36 137 -3 c75 -2 155 -6 176 -9z"/>
                                      </g>
                                    </svg>

                                  </a>
                                )
                              ))}

                            {/*   <Link to="#" onClick={() => addToCart(productData, row)}>
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM252 160c0 11 9 20 20 20h44v44c0 11 9 20 20 20s20-9 20-20V180h44c11 0 20-9 20-20s-9-20-20-20H356V96c0-11-9-20-20-20s-20 9-20 20v44H272c-11 0-20 9-20 20z" /></svg>
                              </Link> */}
                            </div>
                          </TableCell>
                        </TableRow>)
                    }
                  })}

                  {headerRows.length > 0 && bodyRows.map((row, index) => {
                    return (<TableRow key={`body-row-${index}`}>
                      {Object.entries(row).map(([key, cell]) => (
                        key === "image" || key === 'price' || key === 'discount' || key === 'economic_code' || key === 'economic_price' || key === 'economic_discount' ? (
                          null
                        ) : (
                          <TableCell key={`body-cell-${key}`} align="center">
                            {cell}
                          </TableCell>
                        )
                      ))}

                      <TableCell align="center" className="divide-y whitespace-nowrap sticky right-0 bg-white">
                        <div className="flex items-center justify-center">
                          {Object.entries(row).map(([key, cell]) => (
                            key === "image" && cell != null && cell !='404' ? (
                              <>

                                <a href={cell} download="" className="text-neutral-50 hover:text-secondary-50">


                                  <svg className="w-6 h-6 mr-2" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 303.000000 219.000000"
                                    preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,219.000000) scale(0.100000,-0.100000)"
                                      fill="#000000" stroke="none">
                                      <path d="M225 2065 c-51 -18 -120 -91 -134 -141 -7 -26 -11 -284 -11 -800 0
-832 -1 -815 58 -877 15 -16 45 -39 67 -50 l40 -22 1185 -2 c754 -2 1205 1
1240 7 74 13 129 53 160 115 l25 50 3 764 c2 682 1 770 -14 813 -21 65 -58
106 -119 134 l-50 24 -1205 -1 c-1013 0 -1211 -2 -1245 -14z m1205 -367 c0 -4
-76 -103 -170 -220 -93 -117 -170 -219 -170 -225 0 -7 8 -13 18 -13 28 0 116
-45 156 -80 119 -105 138 -302 42 -443 -128 -189 -417 -223 -642 -77 -35 24
-68 50 -73 58 -7 12 5 36 40 89 27 40 52 73 55 73 4 0 20 -11 36 -26 80 -66
202 -99 303 -80 46 9 106 63 124 111 41 114 -67 215 -229 215 l-57 0 -22 51
c-13 31 -18 56 -12 63 101 128 251 326 251 331 0 3 -60 4 -132 3 l-133 -3 -5
-50 -5 -50 -90 0 -90 0 -3 102 c-4 142 9 165 98 175 56 6 710 2 710 -4z m474
-3 c121 -17 253 -87 324 -173 113 -134 157 -348 112 -546 -30 -136 -131 -272
-246 -333 -110 -58 -149 -65 -382 -70 -232 -6 -262 -1 -281 45 -8 19 -10 143
-9 392 l3 365 100 0 100 0 3 -312 2 -313 108 0 c124 0 194 17 260 62 181 125
188 498 13 637 -79 63 -125 73 -353 78 -115 3 -208 8 -208 12 0 8 58 79 107
132 l34 36 137 -3 c75 -2 155 -6 176 -9z"/>
                                    </g>
                                  </svg>

                                </a>
                              </>
                            ) : key === "image" && cell == null && (
                              <Link to="/login" onClick={handleLoginClick} className="text-neutral-50 hover:text-secondary-50">
                                <svg className="w-6 h-6 mr-2" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 303.000000 219.000000"
                                  preserveAspectRatio="xMidYMid meet">

                                  <g transform="translate(0.000000,219.000000) scale(0.100000,-0.100000)"
                                    fill="#000000" stroke="none">
                                    <path d="M225 2065 c-51 -18 -120 -91 -134 -141 -7 -26 -11 -284 -11 -800 0
-832 -1 -815 58 -877 15 -16 45 -39 67 -50 l40 -22 1185 -2 c754 -2 1205 1
1240 7 74 13 129 53 160 115 l25 50 3 764 c2 682 1 770 -14 813 -21 65 -58
106 -119 134 l-50 24 -1205 -1 c-1013 0 -1211 -2 -1245 -14z m1205 -367 c0 -4
-76 -103 -170 -220 -93 -117 -170 -219 -170 -225 0 -7 8 -13 18 -13 28 0 116
-45 156 -80 119 -105 138 -302 42 -443 -128 -189 -417 -223 -642 -77 -35 24
-68 50 -73 58 -7 12 5 36 40 89 27 40 52 73 55 73 4 0 20 -11 36 -26 80 -66
202 -99 303 -80 46 9 106 63 124 111 41 114 -67 215 -229 215 l-57 0 -22 51
c-13 31 -18 56 -12 63 101 128 251 326 251 331 0 3 -60 4 -132 3 l-133 -3 -5
-50 -5 -50 -90 0 -90 0 -3 102 c-4 142 9 165 98 175 56 6 710 2 710 -4z m474
-3 c121 -17 253 -87 324 -173 113 -134 157 -348 112 -546 -30 -136 -131 -272
-246 -333 -110 -58 -149 -65 -382 -70 -232 -6 -262 -1 -281 45 -8 19 -10 143
-9 392 l3 365 100 0 100 0 3 -312 2 -313 108 0 c124 0 194 17 260 62 181 125
188 498 13 637 -79 63 -125 73 -353 78 -115 3 -208 8 -208 12 0 8 58 79 107
132 l34 36 137 -3 c75 -2 155 -6 176 -9z"/>
                                  </g>
                                </svg>
                              </Link>
                            )
                          ))}

                         {/*  <Link to="#" onClick={() => addToCart(productData, row)}>
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM252 160c0 11 9 20 20 20h44v44c0 11 9 20 20 20s20-9 20-20V180h44c11 0 20-9 20-20s-9-20-20-20H356V96c0-11-9-20-20-20s-20 9-20 20v44H272c-11 0-20 9-20 20z" /></svg>
                          </Link> */}
                        </div>
                      </TableCell>
                    </TableRow>)
                  })}
                </TableBody>
              </Table>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
