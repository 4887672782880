import React, { FC } from "react";
import { Link} from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import blockImage1 from "images/For-Mould-Accessories-1.png";
import blockImage2 from "images/For-Press-Tool-Accessories.jpg";
import blockImage3 from "images/Dies-and-Moulds-Polishing-and-Finishing-Tools.png";


export interface CategoryProps {
  className?: string;
  price?: number;
  contentClass?: string;
}

const Category: FC<CategoryProps> = ({
}) => {
  return (
    <div className="xl:px-24 pt-10 pb-14 lg:pb-24 xl:pt-24 relative space-y-24 lg:space-y-32">
    {/*  <Category /> */}
    <div className="container-fluid">
    <div className="grid grid-cols-1 md:grid-cols-3 xl:gap-8 gap-6">
      <Link
        to={`/mould-accessories`}
        className={`nc-CardCategory3 block`}>
        <div className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group bg-blue-50`}>
          <div className="grid grid-cols-2">
          <div>
            <NcImage
              src={blockImage1}
              containerClassName="absolute inset-5 sm:inset-8"
              className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
            />
          </div>
          <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

          <div>
            <div className="absolute inset-5 sm:inset-8 flex flex-col">
              <div className="max-w-xs">
                <span className={`block mb-2 text-sm text-slate-700`}>
                  Accessories for
                </span>
                <h2 className={`text-base md:text-2xl text-slate-900 font-semibold w-[46%]`}>
                Mould Manufacturing
                </h2>
              </div>
              <div className="mt-auto">
                <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium py-3 px-4 sm:py-3.5 sm:px-6  ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 nc-shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">Show me all</button>
                </div>
            </div>
          </div>
          </div>
        </div>
      </Link>
      <Link
        to={`/press-tool-accessories`}
        className={`nc-CardCategory3 block`}>
        <div className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group bg-blue-50`}>
        <div className="grid grid-cols-2">
          <div>
            <NcImage
              src={blockImage2}
              containerClassName="absolute inset-5 sm:inset-8"
              className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
            />
          </div>
          <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

          <div>
            <div className="absolute inset-5 sm:inset-8 flex flex-col">
              <div className="max-w-xs">
                <span className={`block mb-2 text-sm text-slate-700`}>
                  Accessories for
                </span>
                <h2 className={`text-base md:text-2xl text-slate-900 font-semibold`}>
                Press Tool
                </h2>
              </div>
              <div className="mt-auto">
                <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium py-3 px-4 sm:py-3.5 sm:px-6  ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 nc-shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">Show me all</button>
                </div>
            </div>
          </div>
        </div>
        </div>
      </Link>
      <Link
              to={`/die-polishing-accessories`}
              className={`nc-CardCategory3 block`}>
              <div className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group bg-blue-50`}>
              <div className="grid grid-cols-2">
                <div>
                  <NcImage
                    src={blockImage3}
                    containerClassName="absolute inset-5 sm:inset-8"
                    className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
                  />
                </div>
                <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

                <div>
                  <div className="absolute inset-5 sm:inset-8 flex flex-col">
                    <div className="max-w-xs">
                      <span className={`block mb-2 text-sm text-slate-700`}>
                        Accessories for
                      </span>
                      <h2 className={`text-base md:text-2xl text-slate-900 font-semibold w-[60%]`}>
                      Die Polishing And Finishing
                      </h2>
                    </div>
                    <div className="mt-auto">
                      <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium py-3 px-4 sm:py-3.5 sm:px-6  ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 nc-shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">Show me all</button>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </Link>
    </div>
    </div>


  </div>
  );
};

export default Category;
