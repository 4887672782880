import React, { useState, ChangeEvent, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import toastr from 'toastr'
import SectionHero2 from "components/SectionHero/SectionHero2";
import Category from "./Category";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import api from '../../constants/api'
import NcImage from "shared/NcImage/NcImage";
import SectionPromo2 from "components/SectionPromo2";
import SectionPromo1 from "components/SectionPromo1";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionPromo3 from "components/SectionPromo3";
import backgroundLineSvg from "images/BackgroundLine.svg";
import ButtonCircle from "shared/Button/ButtonCircle";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import rightImgDemo from "images/promo3.png";
import Input from "shared/Input/Input";
import blockImage1 from "images/For-Mould-Accessories-1.png";
import blockImage2 from "images/For-Press-Tool-Accessories.jpg";
import blockImage3 from "images/Dies-and-Moulds-Polishing-and-Finishing-Tools.png";


import "toastr/build/toastr.min.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";

toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 5000,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};


function PageHome() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subscriberEmail, setSubscriberEmail] = useState('')
  const [updateListData, setUpdateListData] = useState(false);

  useEffect(() => {
    getListData();
  }, []);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  const getListData = () => {
    setUpdateListData(true);
    dispatch(clearData())
    const filterData = {};
    dispatch(onGetList(filterData, '', api.frontCategory));
  }

  if (data!=null && updateListData == true) {
     if(data.category && data.category.length!=0){
        setUpdateListData(false);
        localStorage.setItem("categoryData", JSON.stringify(data.category));
     }
  }


  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSubscriberEmail(e.target.value);
  };
  const handleSubmit = async () => {
    const options = { email: subscriberEmail }
    const url = process.env.REACT_APP_API_URL + api.frontCreateSubscriber
    try {
      const response = await axios.post(url, options);

      if (response.data.statusCode === 200) {
        toastr.success(response.data.message);
        // alert(response.data.message)
        setSubscriberEmail('');
      } else {
        toastr.error(response.data.message);
        // alert(response.data.message)
      }

      return response.data.statusCode;
    } catch (error) {
      console.error('API Error:', error);
      toastr.error('An error occurred while processing your request');
      // alert(error)
      // throw error;
      // Rethrow the error
    }
  }

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Home | Vijaydeep</title>
      </Helmet>
      {/* SECTION HERO */}
      <SectionHero2 />
      <div className="xl:px-24 p-5 relative space-y-24 lg:space-y-32 mt-6 mb-0 md:mt-14 md:mb-10 xl:mt-10 xl:mb-8">
        {/*  <Category /> */}
        <div className="container-fluid">
          <div className="grid grid-cols-1 md:grid-cols-3 xl:gap-8 gap-6">
            <Link
              to={`mould-accessories`}
              className={`nc-CardCategory3 block`}>
              <div className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group bg-blue-50`}>
                <div className="grid grid-cols-2">
                <div>
                  <NcImage
                    src={blockImage1}
                    containerClassName="absolute inset-5 sm:inset-8"
                    className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
                  />
                </div>
                <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

                <div>
                  <div className="absolute inset-5 sm:inset-8 flex flex-col">
                    <div className="max-w-xs">
                      <span className={`block mb-2 text-sm text-slate-700`}>
                        Accessories for
                      </span>
                      <h2 className={`text-base md:text-2xl text-slate-900 font-semibold w-[46%]`}>
                      Mould Manufacturing
                      </h2>
                    </div>
                    <div className="mt-auto">
                      <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium py-3 px-4 sm:py-3.5 sm:px-6  ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 nc-shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">Show me all</button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
            </Link>

            <Link
              to={`/press-tool-accessories`}
              className={`nc-CardCategory3 block`}>
              <div className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group bg-blue-50`}>
              <div className="grid grid-cols-2">
                <div>
                  <NcImage
                    src={blockImage2}
                    containerClassName="absolute inset-5 sm:inset-8"
                    className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
                  />
                </div>
                <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

                <div>
                  <div className="absolute inset-5 sm:inset-8 flex flex-col">
                    <div className="max-w-xs">
                      <span className={`block mb-2 text-sm text-slate-700`}>
                        Accessories for
                      </span>
                      <h2 className={`text-base md:text-2xl text-slate-900 font-semibold`}>
                      Press Tool
                      </h2>
                    </div>
                    <div className="mt-auto">
                      <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium py-3 px-4 sm:py-3.5 sm:px-6  ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 nc-shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">Show me all</button>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </Link>

            <Link
              to={`/die-polishing-accessories`}
              className={`nc-CardCategory3 block`}>
              <div className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group bg-blue-50`}>
              <div className="grid grid-cols-2">
                <div>
                  <NcImage
                    src={blockImage3}
                    containerClassName="absolute inset-5 sm:inset-8"
                    className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
                  />
                </div>
                <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

                <div>
                  <div className="absolute inset-5 sm:inset-8 flex flex-col">
                    <div className="max-w-xs">
                      <span className={`block mb-2 text-sm text-slate-700`}>
                        Accessories for
                      </span>
                      <h2 className={`text-base md:text-2xl text-slate-900 font-semibold w-[60%]`}>
                      Die Polishing And Finishing
                      </h2>
                    </div>
                    <div className="mt-auto">
                      <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium py-3 px-4 sm:py-3.5 sm:px-6  ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 nc-shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">Show me all</button>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="xl:px-24 p-5">
        <div className="nc-SectionStatistic relative">
          <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-7 text-neutral-900 dark:text-neutral-50">
            <h2 className=" text-2xl md:text-4xl font-semibold">Company Statistics</h2>
          </div>
          <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-4 xl:gap-8">
            <div className="p-6 bg-[#F9FAFB] rounded-2xl ">
              <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                30 +
              </h3>
              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                Years Of Experience
              </span>
            </div>
            <div className="p-6 bg-[#F9FAFB] rounded-2xl ">
              <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                3000 +
              </h3>
              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                Customers Across India
              </span>
            </div>
            <div className="p-6 bg-[#F9FAFB] rounded-2xl ">
              <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                10000 +
              </h3>
              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                Product Range
              </span>
            </div>
            <div className="p-6 bg-[#F9FAFB] rounded-2xl ">
              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                First Indian Company<br /> to Provide <span className="bg-black text-white rounded-sm px-1">3D</span> data
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION */}
      <div className={`nc-SectionPromo3 lg:pt-20 pt-10`}>
        <div className="relative flex flex-col lg:flex-row bg-slate-50 dark:bg-slate-800 rounded-2xl sm:rounded-[40px] p-4 sm:p-5 lg:p-24">
          <div className="absolute inset-0">
            {/* <img
              className="absolute w-full h-full object-contain object-bottom dark:opacity-5"
              src={backgroundLineSvg}
              alt="backgroundLineSvg"
            /> */}
          </div>

          <div className="lg:w-[50%] max-w-lg relative">
            <h2 className="font-semibold text-4xl md:text-5xl">
              Keep up to date
            </h2>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            Stay upated by subsribing to Vijaydeep news letter. Our team will
            keep updating you with latest product development, events & news.
            </span>
            <form className="mt-10 relative max-w-sm">
              <Input
                required
                aria-required
                placeholder="Enter your email"
                type="email"
                rounded="rounded-full"
                value={subscriberEmail}
                onChange={handleChange}
              />
              <ButtonCircle
                type="button"
                className="absolute transform top-1/2 -translate-y-1/2 right-1"
                onClick={handleSubmit}
              >
                <ArrowSmallRightIcon className="w-6 h-6" />
              </ButtonCircle>
            </form>
          </div>
        </div>
      </div>

      {/* SECTION OFFICE ADDRESH */}
      <div className="bg-[#06163A] py-14">
        <div className="container grid grid-cols-2 lg:grid-cols-3 gap-10">
          <div className="text-center space-y-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" fill="#ffff" height="30" width="30" viewBox="0 0 512 512"><path d="M51.7 295.1l31.7 6.3c7.9 1.6 16-.9 21.7-6.6l15.4-15.4c11.6-11.6 31.1-8.4 38.4 6.2l9.3 18.5c4.8 9.6 14.6 15.7 25.4 15.7c15.2 0 26.1-14.6 21.7-29.2l-6-19.9c-4.6-15.4 6.9-30.9 23-30.9h2.3c13.4 0 25.9-6.7 33.3-17.8l10.7-16.1c5.6-8.5 5.3-19.6-.8-27.7l-16.1-21.5c-10.3-13.7-3.3-33.5 13.4-37.7l17-4.3c7.5-1.9 13.6-7.2 16.5-14.4l16.4-40.9C303.4 52.1 280.2 48 256 48C141.1 48 48 141.1 48 256c0 13.4 1.3 26.5 3.7 39.1zm407.7 4.6c-3-.3-6-.1-9 .8l-15.8 4.4c-6.7 1.9-13.8-.9-17.5-6.7l-2-3.1c-6-9.4-16.4-15.1-27.6-15.1s-21.6 5.7-27.6 15.1l-6.1 9.5c-1.4 2.2-3.4 4.1-5.7 5.3L312 330.1c-18.1 10.1-25.5 32.4-17 51.3l5.5 12.4c8.6 19.2 30.7 28.5 50.5 21.1l2.6-1c10-3.7 21.3-2.2 29.9 4.1l1.5 1.1c37.2-29.5 64.1-71.4 74.4-119.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm144.5 92.1c-2.1 8.6 3.1 17.3 11.6 19.4l32 8c8.6 2.1 17.3-3.1 19.4-11.6s-3.1-17.3-11.6-19.4l-32-8c-8.6-2.1-17.3 3.1-19.4 11.6zm92-20c-2.1 8.6 3.1 17.3 11.6 19.4s17.3-3.1 19.4-11.6l8-32c2.1-8.6-3.1-17.3-11.6-19.4s-17.3 3.1-19.4 11.6l-8 32zM343.2 113.7c-7.9-4-17.5-.7-21.5 7.2l-16 32c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2l16-32c4-7.9 .7-17.5-7.2-21.5z" /></svg>
            <h4 className="text-white text-lg font-semibold">Corporate Office
              Mumbai</h4>
            <p className="text-white text-sm">#205, 2nd Floor, T-Square, <br />Chandivali Junction, Saki Vihar Road, <br />Saki Naka,Andheri East, <br />Mumbai – 400072. India.</p>
          </div>
          <div className="text-center space-y-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" fill="#ffff" height="30" width="30" viewBox="0 0 512 512"><path d="M51.7 295.1l31.7 6.3c7.9 1.6 16-.9 21.7-6.6l15.4-15.4c11.6-11.6 31.1-8.4 38.4 6.2l9.3 18.5c4.8 9.6 14.6 15.7 25.4 15.7c15.2 0 26.1-14.6 21.7-29.2l-6-19.9c-4.6-15.4 6.9-30.9 23-30.9h2.3c13.4 0 25.9-6.7 33.3-17.8l10.7-16.1c5.6-8.5 5.3-19.6-.8-27.7l-16.1-21.5c-10.3-13.7-3.3-33.5 13.4-37.7l17-4.3c7.5-1.9 13.6-7.2 16.5-14.4l16.4-40.9C303.4 52.1 280.2 48 256 48C141.1 48 48 141.1 48 256c0 13.4 1.3 26.5 3.7 39.1zm407.7 4.6c-3-.3-6-.1-9 .8l-15.8 4.4c-6.7 1.9-13.8-.9-17.5-6.7l-2-3.1c-6-9.4-16.4-15.1-27.6-15.1s-21.6 5.7-27.6 15.1l-6.1 9.5c-1.4 2.2-3.4 4.1-5.7 5.3L312 330.1c-18.1 10.1-25.5 32.4-17 51.3l5.5 12.4c8.6 19.2 30.7 28.5 50.5 21.1l2.6-1c10-3.7 21.3-2.2 29.9 4.1l1.5 1.1c37.2-29.5 64.1-71.4 74.4-119.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm144.5 92.1c-2.1 8.6 3.1 17.3 11.6 19.4l32 8c8.6 2.1 17.3-3.1 19.4-11.6s-3.1-17.3-11.6-19.4l-32-8c-8.6-2.1-17.3 3.1-19.4 11.6zm92-20c-2.1 8.6 3.1 17.3 11.6 19.4s17.3-3.1 19.4-11.6l8-32c2.1-8.6-3.1-17.3-11.6-19.4s-17.3 3.1-19.4 11.6l-8 32zM343.2 113.7c-7.9-4-17.5-.7-21.5 7.2l-16 32c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2l16-32c4-7.9 .7-17.5-7.2-21.5z" /></svg>
            <h4 className="text-white text-lg font-semibold">Pune</h4>
            <p className="text-white text-sm">F-11/12, Vishweshwar Ind.<br /> Premises (VIP Estate),<br />PCNTDA, Bhosari,
            <br />Pune – 411026. India</p>
          </div>
          <div className="text-center space-y-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto" fill="#ffff" height="30" width="30" viewBox="0 0 512 512"><path d="M51.7 295.1l31.7 6.3c7.9 1.6 16-.9 21.7-6.6l15.4-15.4c11.6-11.6 31.1-8.4 38.4 6.2l9.3 18.5c4.8 9.6 14.6 15.7 25.4 15.7c15.2 0 26.1-14.6 21.7-29.2l-6-19.9c-4.6-15.4 6.9-30.9 23-30.9h2.3c13.4 0 25.9-6.7 33.3-17.8l10.7-16.1c5.6-8.5 5.3-19.6-.8-27.7l-16.1-21.5c-10.3-13.7-3.3-33.5 13.4-37.7l17-4.3c7.5-1.9 13.6-7.2 16.5-14.4l16.4-40.9C303.4 52.1 280.2 48 256 48C141.1 48 48 141.1 48 256c0 13.4 1.3 26.5 3.7 39.1zm407.7 4.6c-3-.3-6-.1-9 .8l-15.8 4.4c-6.7 1.9-13.8-.9-17.5-6.7l-2-3.1c-6-9.4-16.4-15.1-27.6-15.1s-21.6 5.7-27.6 15.1l-6.1 9.5c-1.4 2.2-3.4 4.1-5.7 5.3L312 330.1c-18.1 10.1-25.5 32.4-17 51.3l5.5 12.4c8.6 19.2 30.7 28.5 50.5 21.1l2.6-1c10-3.7 21.3-2.2 29.9 4.1l1.5 1.1c37.2-29.5 64.1-71.4 74.4-119.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm144.5 92.1c-2.1 8.6 3.1 17.3 11.6 19.4l32 8c8.6 2.1 17.3-3.1 19.4-11.6s-3.1-17.3-11.6-19.4l-32-8c-8.6-2.1-17.3 3.1-19.4 11.6zm92-20c-2.1 8.6 3.1 17.3 11.6 19.4s17.3-3.1 19.4-11.6l8-32c2.1-8.6-3.1-17.3-11.6-19.4s-17.3 3.1-19.4 11.6l-8 32zM343.2 113.7c-7.9-4-17.5-.7-21.5 7.2l-16 32c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2l16-32c4-7.9 .7-17.5-7.2-21.5z" /></svg>
            <h4 className="text-white text-lg font-semibold">Ahmedabad</h4>
            <p className="text-white text-sm">No. 18, Shiv Shakti Estate,<br /> Near Gujarat Bottling,<br /> Rakhial, <br />Ahmedabad 380023. India</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default PageHome;
